import request from "../../utils/request";

 //根据过滤条件，获取游戏信息
 export const getWebFilterConditionalGameProductList = (data)=>{
    return  request({
         method:'POST',
         url:'/game/getWebFilterConditionalGameProductList',
         data 
     })
 }
 
 //收藏或者取消收藏操作
 export const webAddOrCancelProductCollect = (data)=>{
    return  request({
         method:'POST',
         url:'/game/webAddOrCancelProductCollect',
         data
     })
 }

//收藏或者取消收藏操作
export const getWebGameAccountProductFilterTemplate = (gameId)=>{
return  request({
        method:'GET',
        url:`/game/getWebGameAccountProductFilterTemplate/${gameId}`
    })
 }

 //alipay
export const commitOrderAndPayForAliPay = (productId)=>{
return  request({
        method:'POST',
        url:`/trade/aliPay/trade/page/pay/${productId}`
    })
}
