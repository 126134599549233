import { render, staticRenderFns } from "./games-select-price-range.vue?vue&type=template&id=2c4748cd&scoped=true&"
import script from "./games-select-price-range.vue?vue&type=script&lang=js&"
export * from "./games-select-price-range.vue?vue&type=script&lang=js&"
import style0 from "./games-select-price-range.vue?vue&type=style&index=0&id=2c4748cd&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c4748cd",
  null
  
)

export default component.exports