<template>
  <div class="games-filter-list">
    <div class="game-filter-content"> 
      <!-- 游戏图标和游戏名称 -->
      <div class="game-icon-and-name">
        <img class="filter-game-icon" :src="tempCurrentSelectGameItem.gameIcon" alt="游戏图标" >
        <p class="filter-game-name">{{ tempCurrentSelectGameItem.gameName }}</p>
      </div>
      <!-- 游戏筛选条件 -->
      <div class="game-select-props">
        <div v-for="(item,index) in gameFilterConditionalList" :key="index">
          <!-- 商品类型 -->
          <game-select-props ref="radioItem" v-if="item.type==='radio'" :dataObject="item"></game-select-props>
          <!-- 高级选项以及蒙层-->
          <div class="advanced-options-item-box-mask" @click="clickMaskHandler()" :style="{display:isShowMask?'block':'none'}"></div>
          <games-select-advanced-options ref="checkboxItem"  v-if="item.type==='checkbox'" :dataObject="item"></games-select-advanced-options>
          
          <!-- 关键词搜索 -->
          <games-select-input-keyword ref="inputItem" v-if="item.type==='input'" :dataObject="item"></games-select-input-keyword>
          <!-- 价格 -->
          <games-select-price-range ref="inputRangeItem" v-if="item.type==='inputRange'" :dataObject="item"></games-select-price-range>
        </div>
        <!-- 确认按钮 -->
        <div class="filter-btn-box"> 
          <div class="filter-btn" @click="clickFilterConditionalBtn()">
            <p class="filter-btn-text">筛选</p>
          </div>
          <div class="reset-filter-btn" @click="clickResetAllSelectedItemsBtn()">
            <p class="reset-filter-btn-text">重置所有筛选</p>
          </div>
        </div>
      </div>
      <!-- 游戏列表 -->
      <games-show-list ref="gameShowList" :currentSelectGameItem="tempCurrentSelectGameItem"></games-show-list>
    </div>
  </div>
</template>

<script>
import GameSelectProps from './games-props-list.vue';
import GamesSelectAdvancedOptions from './games-select-advanced-options.vue';
import GamesSelectInputKeyword from './games-select-input-keyword.vue';
import GamesSelectPriceRange from './games-select-price-range.vue';
import GamesShowList from './games-show-list.vue';
import { mapState,mapMutations } from 'vuex';
import {getWebGameAccountProductFilterTemplate} from '../../../services/buyList/index';
export default {
  name:'GamesFilterList',
  components:{
    GameSelectProps,
    GamesSelectAdvancedOptions,
    GamesSelectInputKeyword,
    GamesSelectPriceRange,
    GamesShowList
  },
  props:{ 
    currentSelectGameItem:{
      type:Object,
      default:null
    }
  },
  data(){
    return{
      gameFilterConditionalList:[
        {
          type:'radio',
          title:'商品类型',
          aliasTitle:'productType',
          dataList:['游戏账号']
        },
        {
          type:'radio',
          title:'系统/区服',
          aliasTitle:'gameZone',
          dataList:['安卓QQ','苹果QQ','安卓微信','苹果微信'],
        },
        {
          type:'radio',
          title:'实名情况',
          aliasTitle:'kycStatus',
          dataList:['有二次实名','无二次实名'],
        },
        {
          type:'radio',
          title:'有无防沉迷',
          aliasTitle:'asFcm',
          dataList:['无防沉迷','有防沉迷'],
        },
        {
          type:'checkbox',
          title:'高级选项',
          // aliasTitle:'advancedOptions',
          dataList:[
            {
              asRadio:true,
              title:'贵族等级',
              aliasTitle:'vipLevel',
              subDataList:['1','2'],
            },
            {
              asRadio:false,
              title:'星元皮肤',
              aliasTitle:'astralSkin',
              subDataList:['零号雷霆','时之思念','幽冥火'],
            },
            {
              asRadio:false,
              title:'内测皮肤',
              aliasTitle:'closeBetaSkin',
              subDataList:['高渐离_玩趣恶龙','干将莫邪_胡桃夹子'],
            },
            {
              asRadio:false,
              title:'荣耀典藏皮肤',
              aliasTitle:'gloryCollectionSkin',
              subDataList:['李白_鸣剑·曳影 小乔_天鹅之梦'],
            },
            {
              asRadio:false,
              title:'传说皮肤',
              aliasTitle:'legendSkin',
              subDataList:['夏洛特_浮生妄','小乔_山海·琳琅生','大乔_挚爱花嫁大乔_挚爱花嫁','诸葛亮_武陵仙君','蔡文姬_花朝如约','瑶_时之祈愿','小乔_音你心动'],
            },
            {
              asRadio:false,
              title:'史诗皮肤',
              aliasTitle:'epicSkin',
              subDataList:['孙膑_天狼运算者','暃_星界游侠','甄姬_冰雪圆舞曲'],
            },
            {
              asRadio:false,
              title:'限定皮肤',
              aliasTitle:'limitSkin',
              subDataList:['张良_古海寻踪','嫦娥_暖冬·兔眠','桑启_海盐诗旅'],
            },
            {
              asRadio:false,
              title:'其它皮肤',
              aliasTitle:'otherSkin',
              subDataList:['云缨_赤焰之缨','澜_孤猎','镜_冰刃幻境'],
            }
          ],
        },
        {
          type:'input',
          title:'关键词搜索',
          aliasTitle:'searchKeyword',
          placeholder:'请输入关键词搜索'
        },
        {
          type:'radio',
          title:'卖家标签',
          aliasTitle:'partnerTag',
          dataList:['普通卖家','合作卖家'],
        },
        {
          type:'inputRange',
          title:'价格',
          aliasTitle:'priceRange', 
          placeholder:'¥'
        }
      ],
      tempCurrentSelectGameItem:null
    }
  }, 
  methods:{
    ...mapMutations('buyList',['setCurrentSelectGameItem','setIsShowMask','setFilterResultForGamesList','resetAllSelectedItems']),
    //点击蒙层
    clickMaskHandler(){
      this.setIsShowMask(false);
    },
    //根据筛选条件，获取游戏列表
    async clickFilterConditionalBtn(){
      //点击筛选按钮，设置回第一页
      this.$refs.gameShowList.filterData.pageNumber = 1 ;
      this.$refs.gameShowList.loadMoreFilterConditionalHandler();
    },
    //重置所有选择项
    clickResetAllSelectedItemsBtn(){
      //单选
      // console.log(this.$refs.inputItem); 
      let tempRadioIndex = 0;
      this.gameFilterConditionalList.forEach((item,index)=>{
        if(item.type=='radio'){
          this.$refs.radioItem[tempRadioIndex].resetRadioAllSelectedItems();
          tempRadioIndex++;
        }
      })
      //多选
      let tempCheckboxIndex = 0;
      this.gameFilterConditionalList.forEach((item,index)=>{
        if(item.type=='checkbox'){
          let templength = this.$refs.checkboxItem[tempCheckboxIndex].currentAdvancedOptionsSelectList.length;
          for(let i = 0;i<templength;i++){
            this.$refs.checkboxItem[tempCheckboxIndex].resetSelection(i);
          }
          tempCheckboxIndex++;
        }
      })
      //输入框
      let tempInputIndex = 0;
      this.gameFilterConditionalList.forEach((item,index)=>{
          if(item.type=='input'){
            this.$refs.inputItem[tempInputIndex].clearStoreHandler();
            tempInputIndex++;
          }
      })
      //价格范围
      let tempInputRangeIndex = 0;
      this.gameFilterConditionalList.forEach((item,index)=>{
          if(item.type=='inputRange'){
            this.$refs.inputRangeItem[tempInputRangeIndex].clearStoreHandler();
            tempInputRangeIndex++;
          }
      })
      //清空所有store记录
      this.resetAllSelectedItems();
    },
    async loadGameAccountProductFilterTemplate(){
        try{
          const {data} = await getWebGameAccountProductFilterTemplate(this.stateCurrentSelectGameItem.id);
          if(data.code===200){
            this.gameFilterConditionalList = data.data;
          }
        }catch(err){}
    }
  },
  computed:{
    ...mapState({
      isShowMask:state=>state.buyList.isShowMask,
      tempSelectFilterConditional:state=>state.buyList.tempSelectFilterConditional,
      filterResultForGamesList:state=>state.buyList.filterResultForGamesList,
      stateCurrentSelectGameItem:state=>state.buyList.currentSelectGameItem
    }),
    // filterData(){
    //   return {
    //     gameId:this.currentSelectGameItem.id,
    //     pageNumber:1,
    //     pageSize:20,
    //     ...this.tempSelectFilterConditional}
    // }
  },
  created(){
    window.scrollTo(0,0);
    if(this.currentSelectGameItem){
      //prop有值，设置到当前页面和store
      this.tempCurrentSelectGameItem = this.currentSelectGameItem;
      this.setCurrentSelectGameItem(this.currentSelectGameItem);
    }else{
      //prop没有值，则从store中获取
      this.tempCurrentSelectGameItem = this.stateCurrentSelectGameItem;
    }
    this.loadGameAccountProductFilterTemplate();
  }
}
</script>

<style lang="less" scoped>
.games-filter-list{
  margin-top: 20px;
  width: 1200px;
  background: #FFFFFF;
  border-radius: 6px;
  .game-filter-content{
    padding-top: 30px;
    padding-bottom: 10px;
    width: 1200px;
    .game-icon-and-name{
      height: 60px;
      margin-left: 30px;
      width: 1140px;
      display: flex;
      align-items: center;
      .filter-game-icon{
        width: 60px;
        height: 60px;
        border-radius: 6px;
      }
      .filter-game-name{
        margin-left: 14px;
        height: 30px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 22px;
        color: #000000;
        line-height: 30px;
        text-align: left;
        font-style: normal;
      }
    }

    .game-select-props{
      margin-top: 30px;
      width: 1140px;
      background: #FFFFFF;

      .advanced-options-item-box-mask{
          display: none;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // background-color: rgba(0, 0, 0, 0.03); 
          z-index: 990;

      }
      
      .filter-btn-box{
        margin-left: 30px;
        margin-top: 30px;
        background: #FFFFFF;
        width: 1140px;
        height: 40px;
        display: flex;
        .filter-btn{
          width: 200px;
          height: 40px;
          background: #F02233;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: default;
          .filter-btn-text{
            height: 21px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 21px;
            text-align: right;
            font-style: normal;
          }
        }
        .reset-filter-btn{
          margin-left: 20px;
          width: 200px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 6px;
          border: 1px solid #E6E6E6;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: default;
          .reset-filter-btn-text{
            height: 21px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #555555;
            line-height: 21px;
            text-align: right;
            font-style: normal;
          }
        }
      }
    }

  }
}
</style>