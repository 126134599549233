import { render, staticRenderFns } from "./games-show-list.vue?vue&type=template&id=0e5b7bfe&scoped=true&"
import script from "./games-show-list.vue?vue&type=script&lang=js&"
export * from "./games-show-list.vue?vue&type=script&lang=js&"
import style0 from "./games-show-list.vue?vue&type=style&index=0&id=0e5b7bfe&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e5b7bfe",
  null
  
)

export default component.exports